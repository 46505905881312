import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ customerStatus, onChange }) => {
  const [data, updateData] = useImmer(customerStatus);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(customerStatus);
  }, [customerStatus]);

  return (
    <>
      <select value={data} onChange={(e) => updateData(e.target.value)}>
        <option value="PARTIALLY_ACTIVE">PARTIALLY_ACTIVE</option>
        <option value="INACTIVE">INACTIVE</option>
      </select>
    </>
  );
};

export default Component;
