import { useImmer } from "use-immer";
import { useEffect } from "react";
import CustomerSuddenHotspotVisitEwPayStatusOption from "./CustomerSuddenHotspotVisitEwPayStatusOption";
import MerchantHotspotStatusOption from "../configPage/MerchantHotspotStatusOption";

const Component = ({ config, onChange }) => {
  const scoreTemplate = {
    customerStatus: "INACTIVE",
    merchantStatus: "HOTSPOT",
    score: 0,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((draft) => {
      draft.scores.push(structuredClone(scoreTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((draft) => {
      draft.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup min. <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMin = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup max. <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMax = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="config-table-double table-with-button">
        <thead>
          <tr>
            <th>Customer status</th>
            <th>Merchant status</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={addScore}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <CustomerSuddenHotspotVisitEwPayStatusOption
                  customerStatus={score.customerStatus}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].customerStatus = e;
                    })
                  }
                ></CustomerSuddenHotspotVisitEwPayStatusOption>
              </td>
              <td>
                <MerchantHotspotStatusOption
                  merchantStatus={score.merchantStatus}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].merchantStatus = e;
                    })
                  }
                ></MerchantHotspotStatusOption>
              </td>
              <td>
                <input
                  type="number"
                  value={score.score}
                  step="0.1"
                  onChange={(e) =>
                    updateData((d) => {
                      d.score = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
