import { useImmer } from "use-immer";
import { useEffect } from "react";
import ApiConnector from "../connector/ApiConnector";
import { useLocation } from "react-router-dom";
import RouteUtilService from "../service/RouteUtilService";
import UtilService from "../service/UtilService";
import CalculateWeightsJobParameterSimulationPage from "../component/simulationPage/CalculateWeightsJobParameterSimulationPage";

const Component = (props) => {
  const [job, updateJob] = useImmer(null);
  const location = useLocation();
  console.log("Here");

  useEffect(async () => {
    console.log("Here");
    const jobId = RouteUtilService.getParam(location, "jobId");
    const jobs = await ApiConnector.getJobs(
      [jobId],
      ["RUN_WEIGHTS_RECALCULATION_JOB"],
    );
    updateJob(jobs[0]);
  }, [location]);

  return (
    <>
      <header className="subheader">
        <h1>Simulation - Result</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Job</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right">&nbsp;</h2>
          </div>
        </div>
        {job && (
          <>
            <table className="config-table">
              <thead>
                <tr>
                  <th>Attribute</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Created</td>
                  <td>{job.created}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{job.status}</td>
                </tr>
              </tbody>
            </table>

            <h3>Job parameter</h3>

            <CalculateWeightsJobParameterSimulationPage
              jobParam={job.parameter}
            ></CalculateWeightsJobParameterSimulationPage>

            <h3>Job result</h3>

            {/* JOB EXCEPTION */}

            {job.result && job.result.message != null && (
              <p>Error: {job.result.message}</p>
            )}

            {/* JOB FINISHED - NO WEIGHTS FOUND */}

            {job.result &&
              job.result.durationSeconds != null &&
              job.result.flattenWeightResults.length === 0 && (
                <p>No weights found for given configuration.</p>
              )}

            {/* JOB FINISHED */}

            {job.result &&
              job.result.durationSeconds != null &&
              job.result.flattenWeightResults.length > 0 && (
                <table className="config-table-double table-scrollable">
                  <thead>
                    <tr>
                      {Object.keys(job.result.flattenWeightResults[0]).map(
                        (key) => (
                          <th key={key}>
                            {UtilService.camelCaseToReadable(key)}
                          </th>
                        ),
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {job.result.flattenWeightResults.map((fwr) => (
                      <tr>
                        {Object.keys(fwr).map((key) => (
                          <td>{fwr[key]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
          </>
        )}
      </main>
    </>
  );
};

export default Component;
