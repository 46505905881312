import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ merchantStatus, onChange }) => {
  const [data, updateData] = useImmer(merchantStatus);

  useEffect(() => {
    onChange(data);
  }, [data]);

  useEffect(() => {
    updateData(merchantStatus);
  }, [merchantStatus]);

  return (
    <>
      <select value={data} onChange={(e) => updateData(e.target.value)}>
        <option value="SEMI_HOTSPOT">SEMI_HOTSPOT</option>
        <option value="HOTSPOT">HOTSPOT</option>
        <option value="RECENT_SEMI_HOTSPOT">RECENT_SEMI_HOTSPOT</option>
        <option value="RECENT_HOTSPOT">RECENT_HOTSPOT</option>
      </select>
    </>
  );
};

export default Component;
