import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>History lookup min [days]</td>
            <td>
              <input
                type="number"
                value={data.rejectHistoryLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.rejectHistoryLookupDaysMin = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>History lookup max [days]</td>
            <td>
              <input
                type="number"
                value={data.rejectHistoryLookupDaysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.rejectHistoryLookupDaysMax = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Suspicious reject score</td>
            <td>
              <input
                type="number"
                value={data.suspiciousRejectScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.suspiciousRejectScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Very suspicious reject score</td>
            <td>
              <input
                type="number"
                value={data.verySuspiciousRejectScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.verySuspiciousRejectScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
