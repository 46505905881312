import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreTemplate = {
    similarityMin: 0,
    similarityMax: 0,
    score: 0,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((draft) => {
      draft.scores.push(structuredClone(scoreTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((draft) => {
      draft.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Unrecognized odometer score</td>
            <td>
              <input
                type="number"
                value={data.unrecognizedValueScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.unrecognizedValueScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup min <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMin =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup max <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMax =
                      e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="config-table-double table-with-button">
        <thead>
          <tr>
            <th>Similarity min.</th>
            <th>Similarity max</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={addScore}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <input
                  type="number"
                  min={0}
                  max={1}
                  step={0.5}
                  value={score.similarityMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].similarityMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  max={1}
                  step={0.5}
                  value={score.similarityMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].similarityMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  min={0}
                  max={1}
                  step={0.5}
                  value={score.score}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].score = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
