import { useImmer } from "use-immer";
import { useEffect } from "react";
import ApiConnector from "../connector/ApiConnector";
import moment from "moment";
import Note from "../component/Note";
import { Link } from "react-router-dom";
import CalculateWeightsJobParameterSimulationPage from "../component/simulationPage/CalculateWeightsJobParameterSimulationPage";
import ResultSimulationPage from "./ResultSimulationPage";
import frontendConstant from "../constant/FrontendConstant";

const Component = (props) => {
  const calculateWeightsJobParameterTemplate = {
    "@type": "CalculateWeightsJobParameter",
    trnDateMin:
      moment().subtract(30, "days").format("YYYY-MM-DDT00:00:00") + "Z",
    trnDateMax: moment().format("YYYY-MM-DDT00:00:00") + "Z",
    falsePositivesMax: 85,
    truePositivesMin: 80,
    runDurationSecondsMax: frontendConstant.lambdaRunDurationSecondsMax,
    threshold: 4.0,
    foundWeightsMax: 10,
  };
  const [jobs, updateJobs] = useImmer(null);
  const [newJob, updateNewJob] = useImmer(
    structuredClone(calculateWeightsJobParameterTemplate),
  );

  useEffect(async () => {
    await loadJobs();
  }, []);

  const loadJobs = async () => {
    const result = await ApiConnector.getJobs(null, [
      "RUN_WEIGHTS_RECALCULATION_JOB",
    ]);
    updateJobs(result);
    if (result.length > 0) {
      updateNewJob(result[0].parameter); // Set new job parameters to recent job parameters to safe User an effort
    }
  };

  const saveJob = async () => {
    const isMaxInitJobs =
      jobs.filter((j) => ["INIT"].includes(j.status)).length >= 2;
    if (isMaxInitJobs) {
      window.alert(
        "There are already jobs in progress. Wait until some of them will finish.",
      );
      return;
    }
    let job = {
      type: "RUN_WEIGHTS_RECALCULATION_JOB",
      parameter: {
        ...newJob,
        trnDateMin: newJob.trnDateMin,
        trnDateMax: newJob.trnDateMax,
      },
    };
    console.log(job);

    await ApiConnector.postJob(job);
    await loadJobs();
  };

  return (
    <>
      <header className="subheader">
        <h1>Simulation</h1>
      </header>
      <main>
        <div className="row">
          <div className="col">
            <h2>Weight recalculation job</h2>
          </div>
          <div className="col-4">
            <h2 className="float-right">
              <a
                className="fa-regular fa-floppy-disk "
                onClick={(e) => saveJob()}
              />
            </h2>
          </div>
        </div>

        <CalculateWeightsJobParameterSimulationPage
          jobParam={newJob}
          onChange={(e) => updateNewJob(e)}
        ></CalculateWeightsJobParameterSimulationPage>

        <h2>Jobs</h2>
        <>
          <table className="config-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Created</th>
                <th>Status</th>
                <th>Result</th>
              </tr>
            </thead>
            <tbody>
              {jobs &&
                jobs.map((job) => (
                  <tr>
                    <td>{job.type}</td>
                    <td>{moment(job.created).format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{job.status}</td>
                    <td>
                      <Link to={"/simulation/result?jobId=" + job.id}>
                        View detail
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      </main>
    </>
  );
};

export default Component;
