import { useImmer } from "use-immer";
import { useEffect } from "react";
import CustomerSuddenHotspotVisitEwPayStatusOption from "./CustomerSuddenHotspotVisitEwPayStatusOption";
import MerchantHotspotStatusOption from "../configPage/MerchantHotspotStatusOption";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              History lookup min. <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    let val = e.target.value;
                    val =
                      val >= data.historyLookupDaysMax
                        ? data.historyLookupDaysMax
                        : val;
                    d.historyLookupDaysMin = val;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              History lookup max. <small>[days]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMax}
                onChange={(e) =>
                  updateData((d) => {
                    let val = e.target.value;
                    val =
                      val <= data.historyLookupDaysMin
                        ? data.historyLookupDaysMin
                        : val;
                    d.historyLookupDaysMax = val;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              New customer limit <small>[EUR]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.newCustomerSpendingLimitEur}
                onChange={(e) =>
                  updateData((d) => {
                    d.newCustomerSpendingLimitEur = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              Inacitve customer limit <small>[EUR]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.inactiveCustomerSpendingLimitEur}
                onChange={(e) =>
                  updateData((d) => {
                    d.inactiveCustomerSpendingLimitEur = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              Active customer <b>min.</b> limit <small>[EUR]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.activeCustomerSpendingLimitMinEur}
                onChange={(e) =>
                  updateData((d) => {
                    d.activeCustomerSpendingLimitMinEur = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              Active customer limit tolerance <small>[%]</small>
            </td>
            <td>
              <input
                type="number"
                value={Math.round(
                  data.activeCustomerSpendingLimitTolerancePercent * 100,
                )}
                onChange={(e) =>
                  updateData((d) => {
                    let val = e.target.value / 100;
                    val = val > 2.0 ? 2.0 : val;
                    val = val < 0 ? 0 : val;
                    d.activeCustomerSpendingLimitTolerancePercent = val;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>
              Limit period history lookup <small>[hours]</small>
            </td>
            <td>
              <input
                type="number"
                value={data.spendingLimitPeriodHoursMin}
                onChange={(e) =>
                  updateData((d) => {
                    let val = e.target.value;
                    val = val > 0 ? 0 : val;
                    d.spendingLimitPeriodHoursMin = val;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>On negative limit score</td>
            <td>
              <input
                type="number"
                value={data.onNegativeLimitScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.onNegativeLimitScore = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
