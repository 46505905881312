import { useImmer } from "use-immer";
import { useEffect } from "react";

const Component = ({ config, onChange }) => {
  const scoreTemplate = {
    passageCountMin: 0,
    passageCountMax: 0,
    score: 0,
  };
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  const addScore = () => {
    updateData((draft) => {
      draft.scores.push(structuredClone(scoreTemplate));
    });
  };

  const removeScore = (index) => {
    updateData((draft) => {
      draft.scores.splice(index, 1);
    });
  };

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>History lookup days min.</td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMin}
                max={data.historyLookupDaysMax}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMin = e.target.value;
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>History lookup days max.</td>
            <td>
              <input
                type="number"
                value={data.historyLookupDaysMax}
                min={data.historyLookupDaysMin}
                onChange={(e) =>
                  updateData((d) => {
                    d.historyLookupDaysMax = e.target.value;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table className="config-table-double table-with-button">
        <thead>
          <tr>
            <th>Passage count min.</th>
            <th>Passage count max</th>
            <th>Score</th>
            <th>
              <a className="fa-solid fa-plus" onClick={addScore}></a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.scores.map((score, index) => (
            <tr key={index}>
              <td>
                <input
                  type="number"
                  value={score.passageCountMin}
                  max={score.passageCountMax}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].passageCountMin = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.passageCountMax}
                  min={score.passageCountMin}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].passageCountMax = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <input
                  type="number"
                  value={score.score}
                  onChange={(e) =>
                    updateData((d) => {
                      d.scores[index].score = e.target.value;
                    })
                  }
                />
              </td>
              <td>
                <a
                  className="fa-solid fa-trash-can"
                  onClick={() => removeScore(index)}
                ></a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Component;
